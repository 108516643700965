import React, { Component } from 'react'
import RadioButton from '../../common/radioButton/radioButton'
import meetingTypeStyles from './meetingType.module.css'
import fonts from '../../../styles/fonts.module.css'
import { connect } from 'react-redux'
import { changeEdges, initFetchMoreVenues, changeMeetingType, MEETINGTYPE_MEETING, MEETINGTYPE_PARTY, MEETINGTYPE_SAUNA, initVenueAttributes } from '../../../state/reduxApp'
import { trackFilterEvent } from '../../../services/analyticsService'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'

class MeetingTypes extends Component {
  fetchMeetingrooms() {
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    this.props.dispatchMeetingType(MEETINGTYPE_MEETING)
    this.props.dispatchInitVenueAttributes()
    trackFilterEvent('meetingType', MEETINGTYPE_MEETING)
  }
  fetchPartyrooms() {
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    this.props.dispatchMeetingType(MEETINGTYPE_PARTY)
    this.props.dispatchInitVenueAttributes()
    trackFilterEvent('meetingType', MEETINGTYPE_PARTY)
  }
  fetchSaunas() {
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    this.props.dispatchMeetingType(MEETINGTYPE_SAUNA)
    this.props.dispatchInitVenueAttributes()
    trackFilterEvent('meetingType', MEETINGTYPE_SAUNA)
  }

  render() {
    return (
      <div className={meetingTypeStyles.meetingTypeWrapper}>
        <div className={meetingTypeStyles.meetingType}>
          <h2 className={[meetingTypeStyles.sectionHeader, fonts.formHeading2].join(' ')}>{this.props.intl.formatMessage({ id: 'search.what' })}</h2>
          <div className={meetingTypeStyles.meetingTypeButtons}>
            <RadioButton
              checked={this.props.meetingType === MEETINGTYPE_MEETING}
              value="meeting"
              label={this.props.intl.formatMessage({ id: 'search.meeting' })}
              onChange={() => this.fetchMeetingrooms()}
              name="meetingType"
            />
            <RadioButton
              checked={this.props.meetingType === MEETINGTYPE_SAUNA}
              value="sauna"
              label={this.props.intl.formatMessage({ id: 'search.sauna' })}
              onChange={() => this.fetchSaunas()}
              name="meetingType"
            />
            <RadioButton
              checked={this.props.meetingType === MEETINGTYPE_PARTY}
              value="party"
              label={this.props.intl.formatMessage({ id: 'search.party' })}
              onChange={() => this.fetchPartyrooms()}
              name="meetingType"
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.search.term,
    meetingType: state.search.meetingType,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
  dispatchInitFetchMoreVenues: callbackLink => dispatch(initFetchMoreVenues(callbackLink)),
  dispatchMeetingType: callbackLink => dispatch(changeMeetingType(callbackLink)),
  dispatchInitVenueAttributes: callbackLink => dispatch(initVenueAttributes(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MeetingTypes))
