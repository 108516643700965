import React from 'react'
import SearchInputs from './searchInputs/searchInputs'
import AdditionalSelections from './additionalSelections/additionalSelections'
import searchStyles from './search.module.css'
import fonts from '../../styles/fonts.module.css'
import { FormattedMessage } from '../../../plugins/gatsby-plugin-intl-custom'

const Search = () => (
  <div className={searchStyles.searchWrapper}>
    <div className={searchStyles.searchHeader}>
      <h1 className={fonts.heading1}>
        <FormattedMessage id="header.title" />
      </h1>
    </div>
    <SearchInputs />
    <AdditionalSelections />
  </div>
)

export default Search
