import React, { Component } from 'react'
import TextInput from '../../../common/textInput/textInput'
import textInputStyles from '../../../common/textInput/textInput.module.css'
import inputIconStyles from '../../../common/inputIconStyles.module.css'
import placesSearchFieldStyles from './placesSearchField.module.css'
import {
  changeSearchBoxText,
  changeSearchTerm,
  changeSearchVenueId,
  showSearchResultsBox,
  hideSearchResultsBox,
  initFetchMoreVenues,
  changeEdges,
  offlineEstablishmentsOnly,
  notOfflineEstablishmentsOnly,
  changeHotelName,
} from '../../../../state/reduxApp'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import LocationIcon from '../../../../../static/svgs/location.svg'
import XIcon from '../../../../../static/svgs/x.svg'
import { boxTextHighlighted } from '../../../../services/searchService'
import { localizeObject } from '../../../../services/localizationService'
import LoadingAnimation from '../../../../../static/svgs/loading_animation.svg'
import { trackFilterEvent } from '../../../../services/analyticsService'
import { injectIntl, FormattedMessage } from '../../../../../plugins/gatsby-plugin-intl-custom'

class PlacesSearchField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.handleSearchBoxTextChange = this.handleSearchBoxTextChange.bind(this)
    this.handleSearchBoxTextClear = this.handleSearchBoxTextClear.bind(this)

    this.showPlacesDropdown = this.showPlacesDropdown.bind(this)
    this.closePlacesDropdown = this.showPlacesDropdown.bind(this)

    this.renderSearchBoxClearIcon = this.renderSearchBoxClearIcon.bind(this)
  }

  showPlacesDropdown(event) {
    event.preventDefault()
    this.setState({ visible: true }, () => {
      document.addEventListener('click', this.closePlacesDropdown)
    })
  }

  closePlacesDropdown() {
    this.setState({ visible: false }, () => {
      document.removeEventListener('click', this.showPlacesDropdown)
    })
  }

  handleSearchBoxTextChange(event) {
    event.preventDefault()
    this.props.dispatchSearchBoxText(event.target.value)
    this.props.dispatchShowSearchResultsBox()
    if (event.target.value === '') {
      this.props.dispatchHideSearchResultsBox()
    }
  }

  handleSearchBoxTextClear(event) {
    event.preventDefault()
    this.props.dispatchSearchBoxText('')
    this.props.dispatchSearchTerm('')
    this.props.dispatchSearchVenueId('')
    this.props.dispatchHideSearchResultsBox()
    this.props.dispatchNotOfflineEstablishmentsOnly()
    this.props.dispatchHotelName('')
    this.props.dispatchInitFetchMoreVenues()
    trackFilterEvent('place', '')
  }

  handleSearchBoxClick() {
    if (this.props.searchTerm) this.props.dispatchShowSearchResultsBox()
  }

  handleSearchTermChange(boxText, searchText, venueId, municipality) {
    this.props.dispatchSearchBoxText(boxText)
    if (searchText) {
      this.props.dispatchSearchVenueId('')
      this.props.dispatchSearchTerm(searchText)
    }
    if (venueId) {
      this.props.dispatchSearchTerm('')
      this.props.dispatchSearchVenueId(venueId)
    }

    this.props.dispatchEdges(undefined)
    this.props.dispatchHideSearchResultsBox()
    this.props.dispatchInitFetchMoreVenues()

    trackFilterEvent('place', boxText)

    if (!municipality) {
      // Undisable date&time - no municipality
      this.props.dispatchNotOfflineEstablishmentsOnly()
      return
    }

    if (localizeObject(municipality, 'fi') === 'Pietari' || localizeObject(municipality, 'fi') === 'Tallinna') {
      this.props.dispatchOfflineEstablishmentsOnly()
      return
    }

    // Undisable date&time - not offline municipality
    this.props.dispatchNotOfflineEstablishmentsOnly()
  }

  renderSearchResults = () => {
    let searchTerm = this.props.searchBoxText
    let locale = 'fi_FI'
    if (this.props.intl.locale === 'sv') locale = 'sv_FI'
    if (this.props.intl.locale === 'en') locale = 'en_GB'
    if (this.props.intl.locale === 'et') locale = 'et_EE'

    if (!this.props.showSearchResultsBox) return null
    return (
      <div>
        <Query query={ROOMS_QUERY} variables={{ searchTerm, locale }} fetchPolicy="cache-and-network" errorPolicy="all">
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className={placesSearchFieldStyles.results}>
                  <div className={placesSearchFieldStyles.noResults + ' ' + placesSearchFieldStyles.loadingWrapper}>
                    <LoadingAnimation className={placesSearchFieldStyles.LoadingAnimation} />
                  </div>
                </div>
              )

            if (error)
              return (
                <div className={placesSearchFieldStyles.results}>
                  <div className={placesSearchFieldStyles.noResults}>{this.props.intl.formatMessage({ id: 'results.noSearchResults' })}</div>
                </div>
              )

            if (data.searchAutocomplete.areas.length < 1 && data.searchAutocomplete.municipalities.length < 1 && data.searchAutocomplete.establishements < 1)
              return (
                <div className={placesSearchFieldStyles.results}>
                  <div className={placesSearchFieldStyles.noResults}>{this.props.intl.formatMessage({ id: 'results.noSearchResults' })}</div>
                </div>
              )

            return (
              <div className={placesSearchFieldStyles.results}>
                {data.searchAutocomplete.areas.map((area, index) => (
                  <div className={placesSearchFieldStyles.area} key={index}>
                    <LocationIcon className={placesSearchFieldStyles.areaLocationIcon} />
                    {/* TODO: Fix this linter warning */}
                    {/* eslint-disable-next-line */}
                    <div
                      className={placesSearchFieldStyles.areaResults}
                      onClick={() => {
                        this.handleSearchTermChange(localizeObject(area, this.props.language), localizeObject(area, this.props.language))
                      }}
                    >
                      {localizeObject(area, this.props.language)}
                    </div>
                  </div>
                ))}
                {data.searchAutocomplete.municipalities.map((municipality, index) => (
                  <div className={placesSearchFieldStyles.municipality} key={index}>
                    <LocationIcon className={placesSearchFieldStyles.municipalityLocationIcon} />
                    {/* TODO: Fix this linter warning */}
                    {/* eslint-disable-next-line */}
                    <div
                      className={placesSearchFieldStyles.municipalityResults}
                      onClick={() => this.handleSearchTermChange(localizeObject(municipality, this.props.language), localizeObject(municipality, this.props.language), undefined, municipality)}
                    >
                      {localizeObject(municipality, this.props.language)}
                    </div>
                  </div>
                ))}
                {data.searchAutocomplete.establishements.map((establishement, index) => (
                  <div className={placesSearchFieldStyles.venue} key={index}>
                    {/* TODO: Fix this linter warning */}
                    {/* eslint-disable-next-line */}
                    <div
                      className={placesSearchFieldStyles.venueResults}
                      onClick={() => this.handleSearchTermChange(localizeObject(establishement.name, this.props.language), undefined, establishement.id, establishement.municipality)}
                    >
                      {localizeObject(establishement.name, this.props.language)}
                    </div>
                  </div>
                ))}
              </div>
            )
          }}
        </Query>
      </div>
    )
  }

  renderSearchBoxClearIcon = searchText => {
    return searchText ? <XIcon className={inputIconStyles.clearIcon} onClick={this.handleSearchBoxTextClear} /> : ''
  }

  render() {
    // E.g. url http://localhost:8000/?establishmentId=522856699 opens Original Sokos Hotel Presidentti venues and then this.props.hotelName has value
    const searchText = this.props.searchBoxText || this.props.hotelName

    return (
      <div className={textInputStyles.inputWrapper}>
        <LocationIcon alt="Location icon" className={inputIconStyles.mainIcon} />
        <TextInput
          id="placesSearchField"
          type="text"
          active={searchText.length > 0 ? 1 : 0}
          label={this.props.intl.formatMessage({ id: 'search.place' })}
          onChange={this.handleSearchBoxTextChange}
          onClick={() => this.handleSearchBoxClick()}
          value={searchText}
        />
        {this.renderSearchBoxClearIcon(searchText)}
        {boxTextHighlighted(this.props.reduxSearch) && (
          <span className={textInputStyles.reminder}>
            <FormattedMessage id="search.placeOrPersonCountIsRequired" />
          </span>
        )}
        {this.renderSearchResults()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchBoxText: state.search.boxText,
    hotelName: state.hotelName,
    searchTerm: state.search.term,
    venueId: state.search.venueId,
    showSearchResultsBox: state.show.searchResultsBox,
    hideSearchResultsBox: state.show.hideSearchResultsBox,
    language: state.language,
    reduxSearch: state.search,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchSearchBoxText: callbackLink => dispatch(changeSearchBoxText(callbackLink)),
  dispatchSearchTerm: callbackLink => dispatch(changeSearchTerm(callbackLink)),
  dispatchSearchVenueId: callbackLink => dispatch(changeSearchVenueId(callbackLink)),
  dispatchShowSearchResultsBox: callbackLink => dispatch(showSearchResultsBox(callbackLink)),
  dispatchHideSearchResultsBox: callbackLink => dispatch(hideSearchResultsBox(callbackLink)),
  dispatchInitFetchMoreVenues: callbackLink => dispatch(initFetchMoreVenues(callbackLink)),
  dispatchOfflineEstablishmentsOnly: callbackLink => dispatch(offlineEstablishmentsOnly(callbackLink)),
  dispatchNotOfflineEstablishmentsOnly: callbackLink => dispatch(notOfflineEstablishmentsOnly(callbackLink)),
  dispatchHotelName: callbackLink => dispatch(changeHotelName(callbackLink)),
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PlacesSearchField))

const ROOMS_QUERY = gql`
  query AutoComplete($searchTerm: String!, $locale: Locale) {
    searchAutocomplete(filter: $searchTerm, locale: $locale) {
      areas {
        fi_FI
        sv_FI
        en_GB
        et_EE
      }
      municipalities {
        fi_FI
        sv_FI
        en_GB
        et_EE
      }
      establishements {
        id
        name {
          fi_FI
          sv_FI
          en_GB
          et_EE
        }
        municipality {
          fi_FI
          sv_FI
          en_GB
          et_EE
        }
      }
    }
  }
`

// NOTE: Possible approach for dropdown hide/show
// showPlacesDropdown(event) {
//   event.preventDefault()
//   if (this.props.searchTerm) this.props.dispatchShowSearchResultsBox()
//   this.setState({ visible: true }, () => {
//     document.addEventListener("click", this.closePlacesDropdown)
//   })
// }

// closePlacesDropdown() {
//   this.setState({ visible: false }, () => {
//     document.removeEventListener("click", this.closePlacesDropdown)
//   })
// }
