// ES5 syntax used because of gatsby-node.js requires it
const { localizeObject } = require('./localizationService')

// export const formatUrl = (edge, language) => {
var formatEdgeUrl = function(edge, language) {
  if (!edge || !edge.establishment || !edge.name) {
    // TODO: What to do with this console.log?
    console.log('UrlService: Edge or edge information missing')
    return
  }

  const urlPrefix = '/'
  let url = localizeObject(edge.establishment.name, language) + localizeObject(edge.name, language)
  url = url
    .replace(/\s/g, '')
    .replace(/ä/g, 'a')
    .replace(/Ä/g, 'A')
    .replace(/ö/g, 'o')
    .replace(/Ö/g, 'O')
    .replace(/'/g, '')
    .replace(/,/g, '')
    .replace(/\+/g, '')
    .replace(/&/g, '')
    .replace(/:/g, '')
  return urlPrefix + url
}

module.exports.formatEdgeUrl = formatEdgeUrl
