import React from 'react'
import { connect } from 'react-redux'
import { MEETINGTYPE_SAUNA, fetchMoreVenues, MEETINGTYPE_MEETING, MEETINGTYPE_PARTY } from '../../state/reduxApp'
import { localizeObject } from '../../services/localizationService'
import { formatEdgeUrl } from '../../services/urlService'
import { tableConfigurationValue } from '../../services/searchService'
import { formatPrice } from '../../services/moneyService'
import MeetingRoomsQuery from '../queries/meetingRoomsQuery'
import ResultCardTableIcons from './resultCardTableIcons/resultCardTableIcons'
import CheckBox from '../common/checkBox/checkBox'
import fonts from '../../styles/fonts.module.css'
import roomsStyles from './rooms.module.css'
import NoSearchResultsImage from '../../../static/svgs/no_search_results.svg'
import LinkIcon from '../../../static/svgs/external_link.svg'
import { trackEvent } from '../../services/analyticsService'
import BreakLogo from '../../../static/images/break_logo.jpg'
import OriginalLogo from '../../../static/images/original_logo.jpg'
import SoloLogo from '../../../static/images/solo_logo.jpg'
import { injectIntl, Link, FormattedMessage } from '../../../plugins/gatsby-plugin-intl-custom'

class Rooms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleImageIndex: 0,
      initialRender: true,
      showOfflineReservables: false,
    }
    this.handleShowOfflineReservablesChange = this.handleShowOfflineReservablesChange.bind(this)
    this.handleFetchMoreVenues = this.handleFetchMoreVenues.bind(this)
  }

  handleShowOfflineReservablesChange() {
    this.setState({ showOfflineReservables: !this.state.showOfflineReservables })
  }

  handleClickLink = edge => event => {
    trackEvent({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: {},
          products: [
            {
              name: edge.establishment.name.fi_FI,
              id: edge.id,
              category: edge.name.fi_FI,
              // Select parent of event target, make an array of the child elements, find out index of event target
              position: Array.from(event.currentTarget.parentElement.children).indexOf(event.currentTarget),
            },
          ],
        },
      },
    })
  }

  handleFetchMoreVenues() {
    this.props.dispatchFetchMoreVenues()
  }

  renderBadge(establishmentName) {
    const name = establishmentName.toString().toLowerCase()
    if (name.match(/^original/)) {
      return (
        <div className={roomsStyles.resultsCardLabel}>
          <img src={OriginalLogo} alt="Original by Sokos Hotel" />
        </div>
      )
    } else if (name.match(/^break/)) {
      return (
        <div className={roomsStyles.resultsCardLabel}>
          <img src={BreakLogo} alt="Break by Sokos Hotel" />
        </div>
      )
    } else if (name.match(/^solo/)) {
      return (
        <div className={roomsStyles.resultsCardLabel}>
          <img src={SoloLogo} alt="Solo by Sokos Hotel" />
        </div>
      )
    }
    return null
  }

  renderImage = (image, index, edge) => {
    if (index !== this.state.visibleImageIndex) return null

    // TODO: Implement to use right size of images.
    return (
      <div className={roomsStyles.resultsCardImageWrapper} key={index}>
        <img className={roomsStyles.resultsCardImage} alt={image.alternativeText || 'Venue image'} src={image.url} loading="lazy" />
        {this.renderBadge(localizeObject(edge.establishment.name, this.props.intl.locale))}
      </div>
    )
  }

  parseHotelNameAndMunicipality = (edge, language) => {
    const hotelName = localizeObject(edge.establishment.name, language)
    const municipality = localizeObject(edge.address.municipality, language)

    //
    if (hotelName.toLowerCase().endsWith(municipality.toLowerCase())) return hotelName
    return `${hotelName}, ${municipality}`
  }

  renderEdge = edge => {
    if (!edge.onlineReservable && !this.state.showOfflineReservables && !this.props.reduxSearch.offlineEstablishmentsOnly) return null
    if (this.props.meetings_daylight && !edge.attributes.includes('Daylight')) return null
    if (this.props.meetings_creativespace && !edge.attributes.includes('CreativeSpace')) return null
    if (this.props.meetings_restaurantservice && !edge.attributes.includes('RestaurantService')) return null
    if (this.props.meetings_accessible && !edge.attributes.includes('Accessible')) return null
    if (this.props.sauna_swimmingpool && !edge.attributes.includes('SwimmingPool')) return null
    if (this.props.sauna_terrace && !edge.attributes.includes('Terrace')) return null
    if (this.props.sauna_meetingspace && !edge.attributes.includes('MeetingSpace')) return null
    if (this.props.sauna_festivespace && !edge.attributes.includes('FestiveSpace')) return null
    if (this.props.celebration_daylight && !edge.attributes.includes('Daylight')) return null
    if (this.props.celebration_restaurantservice && !edge.attributes.includes('RestaurantService')) return null
    if (this.props.celebration_band && !edge.attributes.includes('Band')) return null
    if (this.props.celebration_stage && !edge.attributes.includes('Stage')) return null
    if (this.props.celebration_accessible && !edge.attributes.includes('Accessible')) return null

    // If venue does not have full day packages, treat it as offline venue
    // Note 16.12. Commented out this logic so that page can be tested. Will have to resolve how to resolve showing venueCards that have no normalCheapestPackage but are desired to be seen
    // if (!edge.normalPriceCheapestPackage && !this.state.showOfflineReservables) return null

    const isCheapestPackageAvailable = edge.normalPriceCheapestPackage && edge.normalPriceCheapestPackage.length >= 1

    let meetingType
    switch (this.props.reduxSearch.meetingType) {
      case MEETINGTYPE_MEETING:
        meetingType = 'Meeting'
        break
      case MEETINGTYPE_SAUNA:
        meetingType = 'Sauna'
        break
      case MEETINGTYPE_PARTY:
        meetingType = 'Celebration'
        break
      default:
        break
    }

    const cheapestPackage = edge.normalPriceCheapestPackage.find(pack => pack.type === meetingType)

    return (
      <Link to={formatEdgeUrl(edge, this.props.intl.locale)} onClick={this.handleClickLink(edge)} className={roomsStyles.resultsCard} key={edge.id} state={this.props.reduxState}>
        <div className={roomsStyles.resultsCardInnerWrapper}>
          <div>{edge.images.map((image, index) => this.renderImage(image, index, edge))}</div>

          <div className={roomsStyles.resultsCardDetails}>
            <div className={roomsStyles.resultsCardHotelNameWrapper}>
              <p className={fonts.resultsCardHotelName}>
                {this.parseHotelNameAndMunicipality(edge, this.props.intl.locale)}
                &nbsp;
              </p>
            </div>

            <div className={roomsStyles.resultsCardRoomNameWrapper}>
              <h3 className={fonts.resultsCardRoomName}>{localizeObject(edge.name, this.props.intl.locale)}</h3>
            </div>
            <div className={roomsStyles.resultsCardPersonCountWrapper}>
              <p className={fonts.resultsCardSmallParagraph}>
                {this.props.intl.formatMessage({ id: 'results.max' })} <span className={fonts.bold}>{edge.maxCapacity}</span> {this.props.intl.formatMessage({ id: 'search.perPerson' })},{' '}
                <span className={fonts.bold}>{edge.size}</span> &#13217;
              </p>
            </div>
          </div>

          <div className={roomsStyles.resultsCardTableIcons}>{this.props.meetingType !== MEETINGTYPE_SAUNA ? <ResultCardTableIcons edge={edge} personCount={this.props.personCount} /> : ''}</div>

          {cheapestPackage && (
            <div className={roomsStyles.cheapestPackageWrapper}>
              <span className={[roomsStyles.cheapestPackageName, fonts.bold, fonts.resultsCardSmallParagraph].join(' ')}>{localizeObject(cheapestPackage.name, this.props.intl.locale)}</span>
              <span className={fonts.resultsCardPriceParagraph}>
                {this.props.intl.formatMessage({ id: 'results.starting' })}{' '}
                <span style={{ fontSize: '1rem' }}>
                  {cheapestPackage.price.toString().endsWith('00') ? (
                    <>{new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(cheapestPackage.price / 100)}</>
                  ) : (
                    <>
                      {formatPrice(cheapestPackage.price)}
                      {' €'}
                    </>
                  )}
                </span>{' '}
                {cheapestPackage && cheapestPackage.isMinimalPricePackage ? '' : `/ ${this.props.intl.formatMessage({ id: 'results.from' })}`}
              </span>
            </div>
          )}

          {edge.onlineReservable === false && !isCheapestPackageAvailable ? (
            <div className={roomsStyles.offlineReservableVenue}>
              <p className={fonts.smallParagraphNoMargin}>{this.props.intl.formatMessage({ id: 'results.noOnlineBooking' })}</p>
            </div>
          ) : (
            ''
          )}
        </div>
      </Link>
    )
  }

  renderNoResults = () => {
    return (
      <div className={roomsStyles.results}>
        <div className={roomsStyles.resultsWrapper}>
          <div className={roomsStyles.noResults}>
            {this.renderCheckboxAndRaflaamolink()}
            <div className={roomsStyles.noResultsImageWrapper}>
              <NoSearchResultsImage className={roomsStyles.noResultsImage} />
              <p className={fonts.noSearchResults}>
                <FormattedMessage id="results.noSearchResults" />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCheckboxAndRaflaamolink = () => {
    const raflaamoLink = (() => {
      switch (this.props.intl.locale) {
        case 'fi':
          return 'https://www.raflaamo.fi/fi/helsinki/juhlat-ja-kokoukset'
        case 'sv':
          return 'https://www.raflaamo.fi/sv/helsingfors/fester-och-moten'
        case 'en':
          return 'https://www.raflaamo.fi/en/helsinki/events-and-meetings'
        default:
          return 'https://www.raflaamo.fi/en/helsinki/events-and-meetings'
      }
    })()

    return (
      <div className={roomsStyles.formComponents}>
        <div>
          <CheckBox
            value="includeOfflineVenues"
            label={this.props.intl.formatMessage({ id: 'results.includeOfflineVenues' })}
            checked={this.state.showOfflineReservables || this.props.reduxSearch.offlineEstablishmentsOnly}
            onChange={this.handleShowOfflineReservablesChange}
          />
        </div>
        <div className={roomsStyles.raflaamoWrapper}>
          <span className={roomsStyles.raflaamo}>
            <a className={fonts.textLink} href={raflaamoLink} target="_blank" rel="noopener noreferrer">
              <LinkIcon className={roomsStyles.raflaamoLinkIcon} />
              <FormattedMessage id="results.raflaamo" />
            </a>
          </span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={roomsStyles.container}>
        {!this.props.edges || this.props.edges.length === 0 ? (
          <>{this.renderNoResults()}</>
        ) : (
          <div className={roomsStyles.results}>
            <div className={roomsStyles.resultsWrapper}>
              {this.renderCheckboxAndRaflaamolink()}
              <div className={roomsStyles.resultsCards}>
                {this.props.edges.map(edge => {
                  // handles resultCardFiltering when PersonCount is given and user also chooses  a tableConfiguration
                  if (this.props.personCount > 0 && tableConfigurationValue(this.props.reduxSearch) !== null) {
                    let filteredEdge = null
                    for (let index = 0; index < edge.tableConfigurations.length; index++) {
                      const item = edge.tableConfigurations[index]
                      if (item.tableConfigurationName.toLowerCase() === this.props.tableConfigurationType.replace('TABLECONFIGURATIONTYPE_', '').toLowerCase()) {
                        if (item.valueRange.max >= this.props.personCount) {
                          filteredEdge = edge
                          break
                        }
                      }
                    }

                    if (filteredEdge) return this.renderEdge(filteredEdge)
                    return null
                  }

                  // Render all if searchService returns tableConfigurationType null e.g. meetingType sauna
                  if (!tableConfigurationValue(this.props.reduxSearch)) return this.renderEdge(edge)

                  // Filter by tableConfigurationType. Preprod-api returned null tableConfiguration values 26.11.2019, that's why !!item
                  if (
                    edge.tableConfigurations.find(
                      item => !!item && item.tableConfigurationName.toLowerCase() === this.props.tableConfigurationType.replace('TABLECONFIGURATIONTYPE_', '').toLowerCase()
                    )
                  ) {
                    return this.renderEdge(edge)
                  }

                  // Return null for outfiltered
                  return null
                })}
              </div>
            </div>
            {this.props.hasNextPage ? (
              <div>
                <button className={[roomsStyles.paginationBtn, fonts.smallParagraph].join(' ')} onClick={this.handleFetchMoreVenues}>
                  <FormattedMessage id="results.seeMoreVenues" />
                </button>
              </div>
            ) : null}
          </div>
        )}
        <MeetingRoomsQuery />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reduxSearch: state.search,
  edges: state.data.edges,
  tableConfigurationType: state.search.tableConfigurationType,
  meetingType: state.search.meetingType,
  reduxState: state,
  personCount: state.search.personCount,
  hasNextPage: state.searchResults.hasNextPage,
  meetings_daylight: state.venueAttributeFilters.meetings_daylight,
  meetings_creativespace: state.venueAttributeFilters.meetings_creativespace,
  meetings_restaurantservice: state.venueAttributeFilters.meetings_restaurantservice,
  meetings_accessible: state.venueAttributeFilters.meetings_accessible,
  sauna_swimmingpool: state.venueAttributeFilters.sauna_swimmingpool,
  sauna_terrace: state.venueAttributeFilters.sauna_terrace,
  sauna_meetingspace: state.venueAttributeFilters.sauna_meetingspace,
  sauna_festivespace: state.venueAttributeFilters.sauna_festivespace,
  celebration_daylight: state.venueAttributeFilters.celebration_daylight,
  celebration_restaurantservice: state.venueAttributeFilters.celebration_restaurantservice,
  celebration_band: state.venueAttributeFilters.celebration_band,
  celebration_accessible: state.venueAttributeFilters.celebration_accessible,
  celebration_stage: state.venueAttributeFilters.celebration_stage,
})

const mapDispatchToProps = dispatch => ({
  dispatchFetchMoreVenues: callbackLink => dispatch(fetchMoreVenues(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Rooms))
