import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MEETINGTYPE_SAUNA } from '../../../state/reduxApp'
import MeetingTypes from '../meetingTypes/meetingTypes'
import PlacesSearchField from './placesSearchField/placesSearchField'
import PersonCountSelector from '../../common/personCountSelector/personCountSelector'
import TableSelector from './tableSelector/tableSelector'
import DateSelector from './dateSelector/dateSelector'
import StartTimeSelector from './startTimeSelector/startTimeSelector'
import EndTimeSelector from './endTimeSelector/endTimeSelector'
import searchInputsStyles from './searchInputs.module.css'
import fonts from '../../../styles/fonts.module.css'
import { injectIntl, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'

class searchInputs extends Component {
  constructor() {
    super()

    this.state = {
      checked: true,
    }

    this.handleCheckboxChecked = this.handleCheckboxChecked.bind(this)
  }

  handleCheckboxChecked = () => {
    this.setState({ checked: !this.state.checked })
  }

  render() {
    return (
      <div className={[searchInputsStyles.searchInputsWrapper, this.props.meetingType === MEETINGTYPE_SAUNA ? searchInputsStyles.meetingTypeSauna : ''].join(' ')}>
        <MeetingTypes />

        <div className={searchInputsStyles.searchInputs}>
          <div className={searchInputsStyles.placeAndCountHeader}>
            <h2 className={fonts.formHeading2}>
              <FormattedMessage id="search.whereAndForHowMany" />
            </h2>
          </div>
          <div className={searchInputsStyles.placesSearchField}>
            <PlacesSearchField />
          </div>
          <div className={searchInputsStyles.personCountSelector}>
            <PersonCountSelector />
          </div>
          <div className={searchInputsStyles.tableSelector}>
            <TableSelector ref={this.myRef} />
          </div>

          <div className={searchInputsStyles.dateAndtimeHeader}>
            <h2 className={fonts.formHeading2}>{this.props.intl.formatMessage({ id: 'search.when' })}</h2>
          </div>
          <div className={searchInputsStyles.dateSelector}>
            <DateSelector />
          </div>

          <div className={searchInputsStyles.startTimeInput}>
            <StartTimeSelector />
          </div>
          <div className={searchInputsStyles.endTimeInput}>
            <EndTimeSelector />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    meetingType: state.search.meetingType,
  }
}

export default connect(mapStateToProps)(injectIntl(searchInputs))
