import React, { Component } from 'react'
import TableSelectButton from '../../../common/tableSelectButton/tableSelectButton'
import TableIcon from '../../../../../static/svgs/table.svg'
import ClassTableIcon from '../../../../../static/svgs/class.svg'
import FoodserviceIcon from '../../../../../static/svgs/foodservice.svg'
import UtableIcon from '../../../../../static/svgs/utable.svg'
import ChairRowIcon from '../../../../../static/svgs/chairrow.svg'
import GroupIcon from '../../../../../static/svgs/group.svg'
import DiplomateIcon from '../../../../../static/svgs/diplomate.svg'
import CocktailIcon from '../../../../../static/svgs/cocktail.svg'
import RoundIcon from '../../../../../static/svgs/round.svg'
import BuffetIcon from '../../../../../static/svgs/buffet.svg'
import ArrowIcon from '../../../../../static/svgs/arrowdown.svg'
import { tableConfigurationDisabled } from '../../../../services/searchService'
import { trackFilterEvent } from '../../../../services/analyticsService'
import { injectIntl, FormattedMessage } from '../../../../../plugins/gatsby-plugin-intl-custom'

import { connect } from 'react-redux'
import {
  // MEETINGTYPE_SAUNA,
  changeTableconfigurationType,
  TABLECONFIGURATIONTYPE_DIPLOMAT,
  TABLECONFIGURATIONTYPE_CLASS_SHAPE,
  TABLECONFIGURATIONTYPE_GROUPWORK,
  TABLECONFIGURATIONTYPE_U_SHAPE,
  TABLECONFIGURATIONTYPE_CHAIRROW,
  TABLECONFIGURATIONTYPE_COCKTAIL,
  TABLECONFIGURATIONTYPE_ROUND,
  TABLECONFIGURATIONTYPE_EAT_BUFFET,
  TABLECONFIGURATIONTYPE_EAT_TABLESERVICE,
} from '../../../../state/reduxApp'

import inputIconStyles from '../../../common/inputIconStyles.module.css'
import tableSelectorStyles from './tableSelector.module.css'

class tableSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      tableConfiguration: '',
    }

    this.tableConfigurations = {
      diplomat: this.props.intl.formatMessage({ id: 'search.tableConfigurations.diplomat' }),
      class_shape: this.props.intl.formatMessage({ id: 'search.tableConfigurations.class_shape' }),
      groupwork: this.props.intl.formatMessage({ id: 'search.tableConfigurations.groupwork' }),
      u_shape: this.props.intl.formatMessage({ id: 'search.tableConfigurations.u_shape' }),
      chairrow: this.props.intl.formatMessage({ id: 'search.tableConfigurations.chairrow' }),
      cocktail: this.props.intl.formatMessage({ id: 'search.tableConfigurations.cocktail' }),
      round: this.props.intl.formatMessage({ id: 'search.tableConfigurations.round' }),
      eat_buffet: this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_buffet' }),
      eat_tableservice: this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_tableservice' }),
    }

    this.showPopup = this.showPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleShowPopUpOnKeyPressed = this.handleShowPopUpOnKeyPressed.bind(this)
    this.handleTableConfigurationChangeOnKeypress = this.handleTableConfigurationChangeOnKeypress.bind(this)
  }

  mapIconsToTableforms = () => {
    return [
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.diplomat' }), icon: <DiplomateIcon />, tableconst: TABLECONFIGURATIONTYPE_DIPLOMAT },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.class_shape' }), icon: <ClassTableIcon />, tableconst: TABLECONFIGURATIONTYPE_CLASS_SHAPE },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.groupwork' }), icon: <GroupIcon />, tableconst: TABLECONFIGURATIONTYPE_GROUPWORK },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.u_shape' }), icon: <UtableIcon />, tableconst: TABLECONFIGURATIONTYPE_U_SHAPE },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.chairrow' }), icon: <ChairRowIcon />, tableconst: TABLECONFIGURATIONTYPE_CHAIRROW },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.cocktail' }), icon: <CocktailIcon />, tableconst: TABLECONFIGURATIONTYPE_COCKTAIL },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.round' }), icon: <RoundIcon />, tableconst: TABLECONFIGURATIONTYPE_ROUND },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_buffet' }), icon: <BuffetIcon />, tableconst: TABLECONFIGURATIONTYPE_EAT_BUFFET },
      { name: this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_tableservice' }), icon: <FoodserviceIcon />, tableconst: TABLECONFIGURATIONTYPE_EAT_TABLESERVICE },
    ]
  }

  handleTableConfigurationChange(value) {
    this.props.dispatchTableconfigurationType(value)
    trackFilterEvent('tableConfiguration', value)
  }

  showPopup(event) {
    event.preventDefault()
    if (tableConfigurationDisabled(this.props.reduxSearch)) return
    this.setState({ visible: true }, () => {
      document.addEventListener('click', this.closePopup)
    })
  }

  handleShowPopUpOnKeyPressed(e) {
    e.preventDefault()
    // if user presses enter key the day gets selected
    if (e.charCode === 13 && this.state.visible === false) {
      this.showPopup(e)
    }
    if (e.charCode === 13 && this.state.visible === true) {
      this.closePopup(e)
    }
  }

  handleTableConfigurationChangeOnKeypress(e, value) {
    e.preventDefault()
    // if user presses enter key the focued tableconfiguration gets selected
    if (e.charCode === 13) {
      this.handleTableConfigurationChange(value)
      this.closePopup(e)
    }
  }

  closePopup(event) {
    const ignorePopup = document.querySelector('#tableSelectorDropdown')
    const ignoreText = document.querySelector('#tableSelectorText')
    // checks if user clicked inside the open popup but not directly on the radiobuttons and prevent closing
    if (event.target === ignorePopup || event.target === ignoreText) {
      return null
    } else {
      this.setState({ visible: false }, () => {
        document.removeEventListener('click', this.closePopup)
      })
    }
  }

  renderArrowIcon() {
    const styles = { transition: 'transform 0.3s ease-in-out' }

    return <ArrowIcon alt="Arrow down icon" style={styles} className={[inputIconStyles.arrowIcon, this.state.visible ? tableSelectorStyles.arrowIconRotated : ''].join(' ')} />
  }

  render() {
    const { visible } = this.state

    const arrowIcon = this.renderArrowIcon()

    const currentTableForm = this.mapIconsToTableforms(this.tableConfigurations).find(item => item.tableconst === this.props.reduxTableConfigurationType)

    return (
      <div className={!tableConfigurationDisabled(this.props.reduxSearch) ? tableSelectorStyles.inputWrapper : tableSelectorStyles.inputWrapperDisabled} aria-haspopup="true" aria-expanded="false">
        <div role="presentation">
          <TableIcon alt="Table icon" className={inputIconStyles.mainIcon} />
          <div className={[tableSelectorStyles.floatingLabel, !currentTableForm ? '' : tableSelectorStyles.transform].join(' ')}>
            <FormattedMessage id="search.tablemodel" />
          </div>
          <div className={tableSelectorStyles.floatingInput} onClick={this.showPopup} onKeyPress={e => this.handleShowPopUpOnKeyPressed(e)} tabIndex="0" role="button" aria-label="showTableSelector">
            {!currentTableForm ? '' : currentTableForm.name}
          </div>

          {arrowIcon}
        </div>
        <div className={[tableSelectorStyles.mask, visible ? tableSelectorStyles.visibleMask : tableSelectorStyles.maskHidden].join(' ')} />

        <ul id="tableSelectorDropdown" className={[tableSelectorStyles.tableSelectorDropdown, visible ? '' : tableSelectorStyles.hidden].join(' ')}>
          {this.props.reduxTableConfigurationType && (
            <li className={tableSelectorStyles.deleteSelection}>
              {/*TODO: localizations*/}
              <button onClick={() => this.handleTableConfigurationChange(null)}>Poista pöytämuodon valinta</button>
            </li>
          )}
          {this.mapIconsToTableforms(this.tableConfigurations).map((item, index) => {
            return (
              <li className={[tableSelectorStyles.tableSelectorDropdownItem, tableSelectorStyles.tableConfigurationsItems].join(' ')} key={index}>
                <TableSelectButton
                  icon={item.icon}
                  key={index}
                  id={'tableSelectorDropdownItem' + index}
                  name="tableConfiguration"
                  value={item.name}
                  checked={this.props.reduxTableConfigurationType === item.tableconst}
                  onChange={() => this.handleTableConfigurationChange(item.tableconst)}
                  tabIndex="0"
                  onKeyPress={e => this.handleTableConfigurationChangeOnKeypress(e, item.tableconst)}
                  aria-label={item}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    meetingType: state.search.meetingType,
    reduxTableConfigurationType: state.search.tableConfigurationType,
    reduxSearch: state.search,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchTableconfigurationType: callbackLink => dispatch(changeTableconfigurationType(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(tableSelector))
