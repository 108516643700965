import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeReservationDate, initFetchMoreVenues, changeEdges } from '../../../../state/reduxApp'
import { parseBackendDateToDate, parseBackendDateToDDMMYYYY, parseDateToBackendFormat } from '../../../../services/timeService'
import textInputStyles from '../../../common/textInput/textInput.module.css'
import inputIconStyles from '../../../common/inputIconStyles.module.css'
import dateSelectorStyles from './dateSelector.module.css'
import CalendarIcon from '../../../../../static/svgs/calendar.svg'
import XIcon from '../../../../../static/svgs/x.svg'
import DatePicker from 'react-datepicker'
import { dateDisabled, dateHighlighted } from '../../../../services/searchService'
import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker-custom.css'
import { trackFilterEvent } from '../../../../services/analyticsService'
import { injectIntl } from '../../../../../plugins/gatsby-plugin-intl-custom'

import { fi, sv, et, enUS } from 'date-fns/locale'
import { addMonths, addDays } from 'date-fns'

class DateSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pickerDate: parseBackendDateToDDMMYYYY(this.props.reduxReservationDate),
      focused: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClearClick = this.handleClearClick.bind(this)
  }

  handleChange = date => {
    this.setState({
      pickerDate: date,
    })
    this.props.dispatchReservationDate(parseDateToBackendFormat(date))
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    trackFilterEvent('date', date)
  }

  handleClearClick = () => {
    this.setState({
      pickerDate: '',
      focused: false,
    })
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    this.props.dispatchReservationDate('')
    trackFilterEvent('date', '')
  }

  renderClearIcon = () => {
    return this.state.pickerDate === '' ? '' : <XIcon alt="clear icon" className={inputIconStyles.clearIcon} onClick={this.handleClearClick} />
  }

  render() {
    const clearIcon = this.renderClearIcon()

    let locale
    switch (this.props.intl.locale) {
      case 'fi':
        locale = fi
        break
      case 'sv':
        locale = sv
        break
      case 'et':
        locale = et
        break
      default:
        locale = enUS
        break
    }

    return (
      <div className={textInputStyles.inputWrapper}>
        <DatePicker
          id="datepicker"
          className={[dateSelectorStyles.textbox, dateDisabled(this.props.reduxSearch) ? dateSelectorStyles.disabled : ''].join(' ')}
          dateFormat="dd.MM.yyyy"
          minDate={addDays(new Date(), 2)}
          maxDate={addMonths(new Date(), 18)}
          locale={locale}
          value={this.state.pickerDate}
          selected={this.props.reduxReservationDate ? parseBackendDateToDate(this.props.reduxReservationDate) : this.state.pickerDate}
          onChange={this.handleChange}
          onFocus={() => {
            this.setState({ focused: true })
          }}
          onBlur={() => {
            if (this.state.pickerDate === '' || this.state.pickerDate === null) {
              this.setState({ focused: false })
            }
          }}
          disabled={dateDisabled(this.props.reduxSearch) ? true : false}
        />
        <CalendarIcon alt="Calendar icon" className={inputIconStyles.mainIcon} />
        <label className={[dateSelectorStyles.inputLabel, this.state.focused || this.state.pickerDate ? dateSelectorStyles.transform : ''].join(' ')} htmlFor="datepicker">
          {this.props.intl.formatMessage({ id: 'search.date' })}
        </label>
        {clearIcon}
        {dateHighlighted(this.props.reduxSearch) && <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'search.dateIsRequired' })}</span>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reduxReservationDate: state.search.reservationDate,
  searchBoxText: state.search.boxText,
  reduxSearch: state.search,
})

const mapDispatchToProps = dispatch => ({
  dispatchReservationDate: callbackLink => dispatch(changeReservationDate(callbackLink)),
  dispatchInitFetchMoreVenues: callbackLink => dispatch(initFetchMoreVenues(callbackLink)),
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DateSelector))
