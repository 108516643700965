import React from 'react'
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation'
import Search from '../../components/search/search'
import Footer from '../../components/footer/footer'
import Rooms from '../../components/room/rooms'
import SEO from '../../components/seo/seo'
import { useIntl } from '../../../plugins/gatsby-plugin-intl-custom'
// import InfoBanner from '../infoBanner/infoBanner'

export default props => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'header.title' })
  const description = intl.formatMessage({ id: 'header.description' })

  return (
    <>
      <SEO title={title} description={description} />
      <Navigation locale={props.path} />
      {/*<InfoBanner path={props.path}/>*/}
      <Container>
        <Search />
        <Rooms />
      </Container>
      <Footer locale={props.path} />
    </>
  )
}
