import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { additionalsDisabled } from '../../../services/searchService'
import CheckBox from '../../common/checkBox/checkBox'
import { LOCALIZEDVENUEATTRIBUTES } from '../../../constants/LOCALIZEDVENUEATTRIBUTES'
import {
  MEETINGTYPE_MEETING,
  MEETINGTYPE_SAUNA,
  MEETINGTYPE_PARTY,
  changeVenueAttributeMeetingsDaylight,
  changeVenueAttributeMeetingsCreativespace,
  changeVenueAttributeMeetingsRestaurantservice,
  changeVenueAttributeMeetingsAccessible,
  changeVenueAttributeSaunaSwimmingpool,
  changeVenueAttributeSaunaTerrace,
  changeVenueAttributeSaunaMeetingspace,
  changeVenueAttributeSaunaFestivespace,
  changeVenueAttributeCelebrationDaylight,
  changeVenueAttributeCelebrationRestaurantservice,
  changeVenueAttributeCelebrationBand,
  changeVenueAttributeCelebrationAccessible,
  changeVenueAttributeCelebrationStage,
  initVenueAttributes,
} from '../../../state/reduxApp'
import additionalSelectionsStyles from './additionalSelections.module.css'
import PlusIcon from '../../../../static/svgs/plus.svg'
import { injectIntl, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'
import { trackFilterEvent } from '../../../services/analyticsService'

const styles = {
  transition: 'all 0.3s ease-in-out',
}
class AdditionalSelections extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleIcons: false,
      visibility: 'hidden',
      meetings_daylight: false,
      meetings_creativespace: false,
      meetings_restaurantservice: false,
      meetings_accessible: false,
      sauna_swimmingpool: false,
      sauna_terrace: false,
      sauna_meetingspace: false,
      sauna_festivespace: false,
      celebration_daylight: false,
      celebration_restaurantservice: false,
      celebration_band: false,
      celebration_accessible: false,
      celebration_stage: false,
    }

    this.handleAdditionalsBlockViewToggle = this.handleAdditionalsBlockViewToggle.bind(this)
    this.handleAdditionalsBlockViewToggleByKeypress = this.handleAdditionalsBlockViewToggleByKeypress.bind(this)
    this.handleMeetingDaylightAttributeChange = this.handleMeetingDaylightAttributeChange.bind(this)
    this.handleMeetingCreativespaceAttributeChange = this.handleMeetingCreativespaceAttributeChange.bind(this)
    this.handleMeetingRestaurantserviceAttributeChange = this.handleMeetingRestaurantserviceAttributeChange.bind(this)
    this.handleMeetingAccessibleAttributeChange = this.handleMeetingAccessibleAttributeChange.bind(this)
    this.handleSaunaSwimmingpoolAttributeChange = this.handleSaunaSwimmingpoolAttributeChange.bind(this)
    this.handleSaunaTerraceAttributeChange = this.handleSaunaTerraceAttributeChange.bind(this)
    this.handleSaunaMeetingspaceAttributeChange = this.handleSaunaMeetingspaceAttributeChange.bind(this)
    this.handleSaunaFestivespaceAttributeChange = this.handleSaunaFestivespaceAttributeChange.bind(this)
    this.handleCelebrationDaylightAttributeChange = this.handleCelebrationDaylightAttributeChange.bind(this)
    this.handleCelebrationRestaurantserviceAttributeChange = this.handleCelebrationRestaurantserviceAttributeChange.bind(this)
    this.handleCelebrationBandAttributeChange = this.handleCelebrationBandAttributeChange.bind(this)
    this.handleCelebrationAccessibleAttributeChange = this.handleCelebrationAccessibleAttributeChange.bind(this)
    this.handleCelebrationStageAttributeChange = this.handleCelebrationStageAttributeChange.bind(this)
  }

  handleAdditionalsBlockViewToggle(event) {
    event.preventDefault()
    this.setState({
      toggleIcons: !this.state.toggleIcons,
      visibility: this.state.visibility === 'hidden' ? 'visible' : 'hidden',
    })
  }

  handleAdditionalsBlockViewToggleByKeypress(e) {
    e.preventDefault()
    if (e.charCode === 13) {
      this.handleAdditionalsBlockViewToggle(e)
    }
  }

  handleMeetingDaylightAttributeChange() {
    this.setState({ meetings_daylight: !this.state.meetings_daylight })
    this.props.dispatchVenueAttributeMeetingsDaylight(!this.state.meetings_daylight)
    if (!this.state.meetings_daylight) trackFilterEvent('additionalSelections', 'Luonnonvalo')
  }

  handleMeetingCreativespaceAttributeChange() {
    this.setState({ meetings_creativespace: !this.state.meetings_creativespace })
    this.props.dispatchVenueAttributeMeetingsCreativespace(!this.state.meetings_creativespace)
    if (!this.state.meetings_creativespace) trackFilterEvent('additionalSelections', 'Luovatila')
  }

  handleMeetingRestaurantserviceAttributeChange() {
    this.setState({ meetings_restaurantservice: !this.state.meetings_restaurantservice })
    this.props.dispatchVenueAttributeMeetingsRestaurantservice(!this.state.meetings_restaurantservice)
    if (!this.state.meetings_restaurantservice) trackFilterEvent('additionalSelections', 'Ravintolapalvelut')
  }

  handleMeetingAccessibleAttributeChange() {
    this.setState({ meetings_accessible: !this.state.meetings_accessible })
    this.props.dispatchVenueAttributeMeetingsAccessible(!this.state.meetings_accessible)
    if (!this.state.meetings_accessible) trackFilterEvent('additionalSelections', 'Esteetön')
  }

  handleSaunaSwimmingpoolAttributeChange() {
    this.setState({ sauna_swimmingpool: !this.state.sauna_swimmingpool })
    this.props.dispatchVenueAttributeSaunaSwimmingpool(!this.state.sauna_swimmingpool)
    if (!this.state.sauna_swimmingpool) trackFilterEvent('additionalSelections', 'Uima-allas/Palju')
  }

  handleSaunaTerraceAttributeChange() {
    this.setState({ sauna_terrace: !this.state.sauna_terrace })
    this.props.dispatchVenueAttributeSaunaTerrace(!this.state.sauna_terrace)
    if (!this.state.sauna_terrace) trackFilterEvent('additionalSelections', 'Parveke/Terassi')
  }

  handleSaunaMeetingspaceAttributeChange() {
    this.setState({ sauna_meetingspace: !this.state.sauna_meetingspace })
    this.props.dispatchVenueAttributeSaunaMeetingspace(!this.state.sauna_meetingspace)
    if (!this.state.sauna_meetingspace) trackFilterEvent('additionalSelections', 'Soveltuu kokoustilaksi')
  }

  handleSaunaFestivespaceAttributeChange() {
    this.setState({ sauna_festivespace: !this.state.sauna_festivespace })
    this.props.dispatchVenueAttributeSaunaFestivespace(!this.state.sauna_festivespace)
    if (!this.state.sauna_festivespace) trackFilterEvent('additionalSelections', 'Soveltuu juhlatilaksi')
  }

  handleCelebrationDaylightAttributeChange() {
    this.setState({ celebration_daylight: !this.state.celebration_daylight })
    this.props.dispatchVenueAttributeCelebrationDaylight(!this.state.celebration_daylight)
    if (!this.state.celebration_daylight) trackFilterEvent('additionalSelections', 'Luonnonvalo')
  }

  handleCelebrationRestaurantserviceAttributeChange() {
    this.setState({ celebration_restaurantservice: !this.state.celebration_restaurantservice })
    this.props.dispatchVenueAttributeCelebrationRestaurantservice(!this.state.celebration_restaurantservice)
    if (!this.state.celebration_restaurantservice) trackFilterEvent('additionalSelections', 'Ravintolapalvelut')
  }

  handleCelebrationBandAttributeChange() {
    this.setState({ celebration_band: !this.state.celebration_band })
    this.props.dispatchVenueAttributeCelebrationBand(!this.state.celebration_band)
    if (!this.state.celebration_band) trackFilterEvent('additionalSelections', 'Tilaan mahtuu bändi')
  }

  handleCelebrationAccessibleAttributeChange() {
    this.setState({ celebration_accessible: !this.state.celebration_accessible })
    this.props.dispatchVenueAttributeCelebrationAccessible(!this.state.celebration_accessible)
    if (!this.state.celebration_accessible) trackFilterEvent('additionalSelections', 'Esteetön')
  }

  handleCelebrationStageAttributeChange() {
    this.setState({ celebration_stage: !this.state.celebration_stage })
    this.props.dispatchVenueAttributeCelebrationStage(!this.state.celebration_stage)
    if (!this.state.celebration_stage) trackFilterEvent('additionalSelections', 'Esiintymislava')
  }

  renderPlusIcons() {
    return this.state.toggleIcons ? (
      <PlusIcon className={additionalSelectionsStyles.clearIcon} onClick={this.handleAdditionalsBlockViewToggle} />
    ) : (
      <PlusIcon className={additionalSelectionsStyles.addIcon} onClick={this.handleAdditionalsBlockViewToggle} />
    )
  }

  renderMeetingAttributes(venueType, venueAttributesKeys, venueAttributesValues) {
    if (venueType === undefined) return null
    if (venueAttributesKeys === undefined) return null
    if (venueAttributesValues === undefined) return null

    return (
      <Fragment>
        {venueAttributesKeys[0] !== undefined ? (
          <div className={additionalSelectionsStyles.checkboxWrapper}>
            <CheckBox
              value={`${venueType}_${venueAttributesKeys[0]}`}
              label={venueAttributesValues[0]}
              disabled={additionalsDisabled(this.props.reduxSearch)}
              checked={this.state.meetings_daylight}
              onChange={this.handleMeetingDaylightAttributeChange}
            />
          </div>
        ) : (
          ''
        )}

        {venueAttributesKeys[1] !== undefined ? (
          <div className={additionalSelectionsStyles.checkboxWrapper}>
            <CheckBox
              value={`${venueType}_${venueAttributesKeys[1]}`}
              label={venueAttributesValues[1]}
              disabled={additionalsDisabled(this.props.reduxSearch)}
              checked={this.state.meetings_creativespace}
              onChange={this.handleMeetingCreativespaceAttributeChange}
            />
          </div>
        ) : (
          ''
        )}

        {venueAttributesKeys[2] !== undefined ? (
          <div className={additionalSelectionsStyles.checkboxWrapper}>
            <CheckBox
              value={`${venueType}_${venueAttributesKeys[2]}`}
              label={venueAttributesValues[2]}
              disabled={additionalsDisabled(this.props.reduxSearch)}
              checked={this.state.meetings_restaurantservice}
              onChange={this.handleMeetingRestaurantserviceAttributeChange}
            />
          </div>
        ) : (
          ''
        )}

        {venueAttributesKeys[3] !== undefined ? (
          <div className={additionalSelectionsStyles.checkboxWrapper}>
            <CheckBox
              value={`${venueType}_${venueAttributesKeys[3]}`}
              label={venueAttributesValues[3]}
              disabled={additionalsDisabled(this.props.reduxSearch)}
              checked={this.state.meetings_accessible}
              onChange={this.handleMeetingAccessibleAttributeChange}
            />
          </div>
        ) : (
          ''
        )}
      </Fragment>
    )
  }
  renderSaunaAttributes(venueType, venueAttributesKeys, venueAttributesValues) {
    if (venueType === undefined) return null
    if (venueAttributesKeys === undefined) return null
    if (venueAttributesValues === undefined) return null

    return (
      <div className={additionalSelectionsStyles.additionalSelectionsWrapper}>
        <div className={additionalSelectionsStyles.additionalQualities}>
          {venueAttributesKeys[0] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[0]}`}
                label={venueAttributesValues[0]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.sauna_swimmingpool}
                onChange={this.handleSaunaSwimmingpoolAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[1] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[1]}`}
                label={venueAttributesValues[1]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.sauna_terrace}
                onChange={this.handleSaunaTerraceAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[2] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[2]}`}
                label={venueAttributesValues[2]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.sauna_meetingspace}
                onChange={this.handleSaunaMeetingspaceAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[3] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[3]}`}
                label={venueAttributesValues[3]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.sauna_festivespace}
                onChange={this.handleSaunaFestivespaceAttributeChange}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
  renderCelebrationAttributes(venueType, venueAttributesKeys, venueAttributesValues) {
    if (venueType === undefined) return null
    if (venueAttributesKeys === undefined) return null
    if (venueAttributesValues === undefined) return null

    return (
      <div className={additionalSelectionsStyles.additionalSelectionsWrapper}>
        <div className={additionalSelectionsStyles.additionalQualities}>
          {venueAttributesKeys[0] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[0]}`}
                label={venueAttributesValues[0]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.celebration_daylight}
                onChange={this.handleCelebrationDaylightAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[1] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[1]}`}
                label={venueAttributesValues[1]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.celebration_restaurantservice}
                onChange={this.handleCelebrationRestaurantserviceAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[2] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[2]}`}
                label={venueAttributesValues[2]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.celebration_band}
                onChange={this.handleCelebrationBandAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[3] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[3]}`}
                label={venueAttributesValues[3]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.celebration_accessible}
                onChange={this.handleCelebrationAccessibleAttributeChange}
              />
            </div>
          ) : (
            ''
          )}

          {venueAttributesKeys[4] !== undefined ? (
            <div className={additionalSelectionsStyles.checkboxWrapper}>
              <CheckBox
                value={`${venueType}_${venueAttributesKeys[4]}`}
                label={venueAttributesValues[4]}
                disabled={additionalsDisabled(this.props.reduxSearch)}
                checked={this.state.celebration_stage}
                onChange={this.handleCelebrationStageAttributeChange}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  render() {
    const language = this.props.intl.locale
    let venueType

    if (this.props.meetingType === MEETINGTYPE_MEETING) {
      venueType = 'meetings'
    }
    if (this.props.meetingType === MEETINGTYPE_SAUNA) {
      venueType = 'sauna'
    }
    if (this.props.meetingType === MEETINGTYPE_PARTY) {
      venueType = 'celebration'
    }

    let venueAttributesKeys = Object.keys(LOCALIZEDVENUEATTRIBUTES[language][venueType])
    let venueAttributesValues = Object.values(LOCALIZEDVENUEATTRIBUTES[language][venueType])

    return (
      <div className={additionalSelectionsStyles.additionalSelectionsSelector}>
        <div className={additionalSelectionsStyles.main}>
          <div className={additionalSelectionsStyles.additional}>
            {/* TODO: Fix this linter warning */}
            {/* eslint-disable-next-line */}
            <div
              className={additionalSelectionsStyles.additionalHeader}
              aria-expanded={false}
              onClick={this.handleAdditionalsBlockViewToggle}
              onKeyPress={e => {
                this.handleAdditionalsBlockViewToggleByKeypress(e)
              }}
            >
              {this.renderPlusIcons()}
              <p>
                <FormattedMessage id="search.additionalRequests" />
              </p>
            </div>
          </div>
        </div>

        <div
          className={additionalSelectionsStyles.sub}
          style={{
            ...styles,
            maxHeight: this.state.toggleIcons ? 'initial' : '0',
            opacity: this.state.toggleIcons ? '1' : '0.5',
            paddingTop: this.state.toggleIcons ? '1.25rem' : '0',
            paddingBottom: this.state.toggleIcons ? '1.25rem' : '0',
          }}
        >
          {venueType === 'meetings' ? this.renderMeetingAttributes(venueType, venueAttributesKeys, venueAttributesValues) : ''}
          {venueType === 'sauna' ? this.renderSaunaAttributes(venueType, venueAttributesKeys, venueAttributesValues) : ''}
          {venueType === 'celebration' ? this.renderCelebrationAttributes(venueType, venueAttributesKeys, venueAttributesValues) : ''}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxSearch: state.search,
    meetingType: state.search.meetingType,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchVenueAttributeMeetingsDaylight: callbackLink => dispatch(changeVenueAttributeMeetingsDaylight(callbackLink)),
  dispatchVenueAttributeMeetingsCreativespace: callbackLink => dispatch(changeVenueAttributeMeetingsCreativespace(callbackLink)),
  dispatchVenueAttributeMeetingsRestaurantservice: callbackLink => dispatch(changeVenueAttributeMeetingsRestaurantservice(callbackLink)),
  dispatchVenueAttributeMeetingsAccessible: callbackLink => dispatch(changeVenueAttributeMeetingsAccessible(callbackLink)),
  dispatchVenueAttributeSaunaSwimmingpool: callbackLink => dispatch(changeVenueAttributeSaunaSwimmingpool(callbackLink)),
  dispatchVenueAttributeSaunaTerrace: callbackLink => dispatch(changeVenueAttributeSaunaTerrace(callbackLink)),
  dispatchVenueAttributeSaunaMeetingspace: callbackLink => dispatch(changeVenueAttributeSaunaMeetingspace(callbackLink)),
  dispatchVenueAttributeSaunaFestivespace: callbackLink => dispatch(changeVenueAttributeSaunaFestivespace(callbackLink)),
  dispatchVenueAttributeCelebrationDaylight: callbackLink => dispatch(changeVenueAttributeCelebrationDaylight(callbackLink)),
  dispatchVenueAttributeCelebrationRestaurantservice: callbackLink => dispatch(changeVenueAttributeCelebrationRestaurantservice(callbackLink)),
  dispatchVenueAttributeCelebrationBand: callbackLink => dispatch(changeVenueAttributeCelebrationBand(callbackLink)),
  dispatchVenueAttributeCelebrationAccessible: callbackLink => dispatch(changeVenueAttributeCelebrationAccessible(callbackLink)),
  dispatchVenueAttributeCelebrationStage: callbackLink => dispatch(changeVenueAttributeCelebrationStage(callbackLink)),
  dispatchInitVenueAttributes: callbackLink => dispatch(initVenueAttributes(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdditionalSelections))
