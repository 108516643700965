import React, { Component } from 'react'

import DiplomateIcon from '../../../../static/svgs/diplomate.svg'
import ClassTableIcon from '../../../../static/svgs/class.svg'
import GroupIcon from '../../../../static/svgs/group.svg'
import UtableIcon from '../../../../static/svgs/utable.svg'
import ChairRowIcon from '../../../../static/svgs/chairrow.svg'
import CocktailIcon from '../../../../static/svgs/cocktail.svg'
import RoundIcon from '../../../../static/svgs/round.svg'
import BuffetIcon from '../../../../static/svgs/buffet.svg'
import FoodserviceIcon from '../../../../static/svgs/foodservice.svg'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'

import fonts from '../../../styles/fonts.module.css'
import resultCardTableIconsStyles from './resultCardTableIcons.module.css'

class resultCardTableIcons extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  getTableConfiguration(tableConfigurationName) {
    if (!this.props.edge) return null
    if (!this.props.edge.tableConfigurations) return null
    if (this.props.edge.tableConfigurations.length < 1) return null

    return this.props.edge.tableConfigurations.find(item => {
      // Preprod-api returned null tableConfiguration values 26.11.2019, that's why !!item
      return !!item && item.tableConfigurationName === tableConfigurationName
    })
  }

  renderMaxCount(tableConfiguration) {
    if (!tableConfiguration || !tableConfiguration.valueRange) return null
    return <p className={fonts.resultsCardMaxCount}>{tableConfiguration.valueRange.max}</p>
  }

  renderIcon(tableConfiguration, icon) {
    if (tableConfiguration) {
      return (
        <div className={resultCardTableIconsStyles.tableIcons}>
          {tableConfiguration.valueRange.max < this.props.personCount ? <div className={resultCardTableIconsStyles.tableIconsTransparentCurtain} /> : ''}
          {icon}
          {this.renderMaxCount(tableConfiguration)}
        </div>
      )
    }

    // No tableConfiguration so render light gray icon
    return (
      <div className={resultCardTableIconsStyles.tableIcons}>
        <div className={resultCardTableIconsStyles.tableIconsTransparentCurtain} />
        {icon}
      </div>
    )
  }

  renderDiplomat() {
    let tableConfiguration = this.getTableConfiguration('Diplomat')
    const icon = <DiplomateIcon alt="Diplomate icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderClass_Shape() {
    let tableConfiguration = this.getTableConfiguration('Class_Shape')
    const icon = <ClassTableIcon alt="Class shape Icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderGroupWork() {
    let tableConfiguration = this.getTableConfiguration('GroupWork')
    const icon = <GroupIcon alt="Group icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderU_Shape() {
    let tableConfiguration = this.getTableConfiguration('U_Shape')
    const icon = <UtableIcon alt="U_shape icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderChairRow() {
    let tableConfiguration = this.getTableConfiguration('ChairRow')
    const icon = <ChairRowIcon alt="Chair row icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderCocktail() {
    let tableConfiguration = this.getTableConfiguration('Cocktail')
    const icon = <CocktailIcon alt="Cocktail icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderRound() {
    let tableConfiguration = this.getTableConfiguration('Round')
    const icon = <RoundIcon alt="Round icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderEat_Buffet() {
    let tableConfiguration = this.getTableConfiguration('Eat_Buffet')
    const icon = <BuffetIcon alt="Buffet icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  renderEat_Tableservice() {
    let tableConfiguration = this.getTableConfiguration('Eat_Tableservice')
    const icon = <FoodserviceIcon alt="Foodservice icon" className={resultCardTableIconsStyles.tableIconsItem} />
    return this.renderIcon(tableConfiguration, icon)
  }

  render() {
    return (
      <div>
        <div className={resultCardTableIconsStyles.tableSelections}>
          <div className={resultCardTableIconsStyles.borderBefore}></div>
          <div className={resultCardTableIconsStyles.tableSelectionsMax}>
            <p className={fonts.resultsCardSmallParagraph}>
              {this.props.intl.formatMessage({ id: 'results.max' })} {this.props.intl.formatMessage({ id: 'results.maxPersonCountPerTableConf' })}
            </p>
          </div>
          <div className={resultCardTableIconsStyles.borderAfter}></div>
          <div className={resultCardTableIconsStyles.tableIconsWrapper}>
            {this.renderDiplomat()}
            {this.renderClass_Shape()}
            {this.renderGroupWork()}
            {this.renderU_Shape()}
            {this.renderChairRow()}
            {this.renderCocktail()}
            {this.renderRound()}
            {this.renderEat_Buffet()}
            {this.renderEat_Tableservice()}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(resultCardTableIcons)
